import Dashboard from "layouts/dashboard";
import Billing from "layouts/billing";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import Cloud from "layouts/cloud";

// @mui icons
import Icon from "@mui/material/Icon";
import SignUp from "./layouts/authentication/sign-up";
import UploadHistory from "./layouts/upload-history";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Billing",
    key: "billing",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/billing",
    component: <Billing />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Upload History",
    key: "upload-history",
    icon: <Icon fontSize="small">cloud_upload</Icon>,
    route: "/upload-history",
    component: <UploadHistory />,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
  // {
  //   type: "collapse",
  //   name: "Cloud",
  //   key: "cloud",
  //   icon: <Icon fontSize="small">Cloud</Icon>,
  //   route: "/cloud",
  //   component: <Cloud />,
  // },
  // {
  //   type: "collapse",
  //   name: "Plan",
  //   key: "plan",
  //   icon: <Icon fontSize="small">api</Icon>,
  //   route: "/plan",
  //   component: <Api />,
  // },
];

export default routes;
