import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import RivalzApi from "../../config/axios/RivalzApi";
import { useEffect, useState } from "react";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import DataTable from "examples/Tables/DataTable";
import { Pagination } from "@mui/material";

const columns = [
  { Header: "name", accessor: "name", width: "20%", align: "left" },
  { Header: "File Size (Bytes)", accessor: "file_size", align: "center" },
  { Header: "Upload hash", accessor: "upload_hash", align: "center" },
  { Header: "Upload At", accessor: "upload_at", align: "center" },
];

function UploadHistory() {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [rows, setRows] = useState([]);

  const { data: uploadHistory, isSuccess } = useQuery({
    queryKey: ["uploadHistory", page, pageSize],
    queryFn: async () => RivalzApi.getUploadHistory(page - 1, pageSize),
  });

  useEffect(() => {
    if (isSuccess) {
      const data = [];
      for (let i = 0; i < uploadHistory.uploadHistories.length; i++) {
        const upload = uploadHistory.uploadHistories[i];
        const { fileName, fileSize, uploadHash, createdAt } = upload;
        data.push({
          name: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {fileName}
            </MDTypography>
          ),
          file_size: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {fileSize}
            </MDTypography>
          ),
          upload_hash: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {uploadHash}
            </MDTypography>
          ),
          upload_at: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {new Date(createdAt).toLocaleString()}
            </MDTypography>
          ),
        });
      }
      setRows(data);
    }
  }, [uploadHistory]);

  useEffect(() => {
    console.log("page", uploadHistory);
  }, [rows]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {uploadHistory?.totalFilesUploaded > 0 ? (
        <MDBox mt={8}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  showPagination={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
          <MDBox display="flex" justifyContent="center" pb={2}>
            <MDBox p={2}>
              <Pagination
                count={uploadHistory ? Math.ceil(uploadHistory.totalFilesUploaded / pageSize) : 0}
                onChange={(e, page) => setPage(page)}
              />
            </MDBox>
          </MDBox>
        </MDBox>
      ) : (
        <MDBox display="flex" justifyContent="center" alignItems="center" height="100%">
          <MDTypography variant="h5" color="text" fontWeight="medium">
            No Upload History
          </MDTypography>
        </MDBox>
      )}
    </DashboardLayout>
  );
}

export default UploadHistory;
