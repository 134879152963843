import { useEffect, useState } from "react";

// react-router-dom components
import { useLocation, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

import MDBox from "components/MDBox";

import { useMaterialUIController, setLayout } from "context";
import useConnection from "../../../config/zustand";
import { useQuery } from "@tanstack/react-query";
import RivalzApi from "../../../config/axios/RivalzApi";
import { Backdrop, CircularProgress } from "@mui/material";
import { Toaster } from "react-hot-toast";

function DashboardLayout({ children }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();
  const { backdrop, setCustomer, setCreditInfo, setUserInfo, setGeneratedEncryptKey } =
    useConnection();

  const { data: customer, isSuccess: fetchCustomerSs } = useQuery({
    queryKey: ["customer"],
    queryFn: async () => RivalzApi.getCustomer(),
  });

  const { data: creditInfo, isSuccess: fetchCreditInfoSs } = useQuery({
    queryKey: ["creditInfo"],
    queryFn: async () => RivalzApi.getCreditInfo(),
  });

  const { data: userInfo, isSuccess: fetchUserInfoSs } = useQuery({
    queryKey: ["userInfo"],
    queryFn: async () => RivalzApi.getUserInfo(),
  });

  useEffect(() => {
    if (!userInfo) return;
    RivalzApi.getEncryptKeyStatus().then((res) => {
      if (!res) {
        RivalzApi.createEncryptKey().then();
      }
    });
  }, [userInfo]);

  useEffect(() => {
    if (fetchUserInfoSs) {
      setUserInfo(userInfo);
    }
  }, [userInfo]);

  useEffect(() => {
    if (fetchCustomerSs) {
      setCustomer(customer);
    }
  }, [customer]);

  useEffect(() => {
    if (fetchCreditInfoSs) {
      setCreditInfo(creditInfo);
    }
  }, [creditInfo]);

  useEffect(() => {
    setLayout(dispatch, "dashboard");
  }, [pathname]);

  return (
    <MDBox
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        p: 3,
        position: "relative",

        [breakpoints.up("xl")]: {
          marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
          transition: transitions.create(["margin-left", "margin-right"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={backdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Toaster position="top-center" reverseOrder={false} />
      {children}
    </MDBox>
  );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
